import Loadable from 'react-loadable';
import Loading from './components/Loading';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';

const Dashboard = Loadable({
	loader: () => import('./views/Dashboard/Dashboard'),
	loading: Loading,
});

const EsimsSearch = Loadable({
	loader: () => import('./views/Esims/EsimSearch'),
	loading: Loading,
});

const EsimImport = Loadable({
	loader: () => import('./views/Esims/EsimImport'),
	loading: Loading,
});

const EsimUpdate = Loadable({
	loader: () => import('./views/Esims/EsimUpdate'),
	loading: Loading,
});

const EsimBags = Loadable({
	loader: () => import('./views/Esims/EsimBags'),
	loading: Loading,
});

const EsimStocks = Loadable({
	loader: () => import('./views/Esims/EsimStocks'),
	loading: Loading,
});

const EsimSend = Loadable({
	loader: () => import('./views/Esims/EsimSend'),
	loading: Loading,
});

const EsimRecover = Loadable({
	loader: () => import('./views/Esims/EsimRecover'),
	loading: Loading,
});

const ProductStock = Loadable({
	loader: () => import('./views/Products/Products'),
	loading: Loading,
});

const VentasSingle = Loadable({
	loader: () => import('./views/Ventas/VentasSingle'),
	loading: Loading,
});

const Logs = Loadable({
	loader: () => import('./views/Logs/Logs'),
	loading: Loading,
});

const routes = [
	{ path: '/esims', exact: true, name: 'eSIMs', component: DefaultLayout },

	// Propios
	{
		path: '/esims/search/:searchOrderNumber?',
		name: 'Buscar eSIM',
		component: EsimsSearch,
	},
	{ path: '/esims/bags', exact: true, name: 'eSIM Bags', component: EsimBags },
	{ path: '/esims/bags/new', exact: true, name: 'eSIM Import', component: EsimImport },
	{ path: '/esims/bags/:bagId', exact: true, name: 'eSIM Update', component: EsimUpdate },
	{ path: '/esims/stock', exact: true, name: 'eSIM Stock', component: EsimStocks },
	{ path: '/esims/send', exact: true, name: 'eSIM Send', component: EsimSend },
	{ path: '/esims/recover', exact: true, name: 'Recover eSIM', component: EsimRecover },

	{ path: '/ventas/:orderId', exact: true, name: 'Ventas', component: VentasSingle },

	{ path: '/products', name: 'Productos' },
	{ path: '/products/:productId', exact: true, name: 'Detalle de Producto', component: ProductStock },

	{ path: '/logs/:search?', name: 'Logs', component: Logs },

	// CoreUI
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard },
];

export default routes;
